
import React from 'react';

function Features() {
  return (
    <section id="features" className="py-24 bg-gray-100 dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-center">
          <div className="w-full lg:w-8/12 px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-gray-800 dark:text-gray-100">
                Идеальные характеристики
              </h2>
              <h5 className="text-xl text-gray-600 dark:text-gray-300 mt-4">
                Только необходимое
              </h5>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-4">
          {/* Удобство использования */}
          <div className="w-full md:w-4/12 px-4 mb-8">
            <div className="text-center p-8 bg-white dark:bg-gray-700 rounded-lg shadow-lg">
              <div className="text-5xl text-orange-500 mb-4">
                <i className="icon icon-diamond6"></i>
              </div>
              <h3 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-4">
                Удобство использования
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Иногда самые простые вещи труднее всего найти. Поэтому я создал новое приложение - прогноз погоды и карты.
              </p>
            </div>
          </div>

          {/* Карта */}
          <div className="w-full md:w-4/12 px-4 mb-8">
            <div className="text-center p-8 bg-white dark:bg-gray-700 rounded-lg shadow-lg">
              <div className="text-5xl text-orange-500 mb-4">
                <i className="icon icon-layers2"></i>
              </div>
              <h3 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-4">
                Карта
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Карта может показывать и отслеживать происходящие в реальном времени осадки.
              </p>
            </div>
          </div>

          {/* Светлая и тёмная тема */}
          <div className="w-full md:w-4/12 px-4 mb-8">
            <div className="text-center p-8 bg-white dark:bg-gray-700 rounded-lg shadow-lg">
              <div className="text-5xl text-orange-500 mb-4">
                <i className="icon icon-business-32"></i>
              </div>
              <h3 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-4">
                Светлая и тёмная тема
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                А главное, что есть светлая и тёмная тема. Тёмная тема очень удобна и приятна для глаз.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;