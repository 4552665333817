import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import PrivacyPolicy from './components/PrivacyPolicy';
import NotFound from './components/NotFound'; // Импортируйте компонент 404
import { ThemeProvider } from './ThemeContext';

function RouterHome() {
  return (
    <ThemeProvider>
      <BrowserRouter basename ="/">
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFound />} /> {/* Маршрут для 404 */}
          </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default RouterHome;