import React from 'react';

const PrivacyPolicy = () => {
  return (
    <section className="pt-50 pb-50 dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <h1 className="text-3xl font-bold mb-6 dark:text-gray-100">Политика конфиденциальности WFL Live</h1>
            <p className="mb-4 dark:text-gray-300">
              WFL Live управляет веб-сайтом <a href="https://meteolive.ru" className="text-blue-500">https://meteolive.ru</a>, на котором предоставляется УСЛУГА.
            </p>
            <p className="mb-4 dark:text-gray-300">
              Эта страница используется для информирования посетителей веб-сайта о нашей политике в отношении сбора, использования и раскрытия Персональной информации, если кто-либо решил воспользоваться нашим Сервисом, веб-сайтом meteolive.ru.
            </p>
            <p className="mb-4 dark:text-gray-300">
              Если вы решите использовать наш Сервис, вы соглашаетесь на сбор и использование информации в связи с этой политикой. Личная информация, которую мы собираем, используется для предоставления и улучшения Сервиса. Мы не будем использовать или передавать вашу информацию кому-либо, кроме случаев, описанных в настоящей Политике конфиденциальности. Наша Политика конфиденциальности была создана с помощью <a href="https://www.termsfeed.com/privacy-policy-generator/" className="text-blue-500">Privacy Policy Generator</a>.
            </p>
            <p className="mb-4 dark:text-gray-300">
              Термины, используемые в настоящей Политике конфиденциальности, имеют те же значения, что и в наших Условиях, которые доступны по адресу <a href="https://meteolive.ru" className="text-blue-500">https://meteolive.ru</a>, если иное не определено в настоящей Политике конфиденциальности.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4 dark:text-gray-100">Сбор и использование информации</h2>
            <p className="mb-4 dark:text-gray-300">
              Для более удобного использования нашего Сервиса мы можем потребовать, чтобы вы предоставили нам определенную личную информацию, включая, помимо прочего, ваше имя, почтовый адрес. Информация, которую мы собираем, будет использоваться для связи с вами или для вашей идентификации.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4 dark:text-gray-100">Данные журнала</h2>
            <p className="mb-4 dark:text-gray-300">
              Мы хотим сообщить вам, что всякий раз, когда вы посещаете наш Сервис, мы собираем информацию, которую ваш браузер отправляет нам, которая называется данными журнала. Эти данные журнала могут включать в себя такую информацию, как адрес интернет-протокола («IP») вашего компьютера, версию браузера, страницы нашего Сервиса, которые вы посещаете, время и дату вашего посещения, время, проведенное на этих страницах, и другую статистику.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4 dark:text-gray-100">Cookies</h2>
            <p className="mb-4 dark:text-gray-300">
              Файлы cookie — это файлы с небольшим объемом данных, которые обычно используются в качестве анонимного уникального идентификатора. Они отправляются в ваш браузер с веб-сайта, который вы посещаете, и сохраняются на жестком диске вашего компьютера.
            </p>
            <p className="mb-4 dark:text-gray-300">
              Наш веб-сайт использует эти «куки» для сбора информации и улучшения нашего Сервиса. У вас есть возможность либо принять, либо отказаться от этих файлов cookie, а также узнать, когда файл cookie отправляется на ваш компьютер. Если вы решите отказаться от наших файлов cookie, вы не сможете использовать некоторые части нашего Сервиса.
            </p>
            <p className="mb-4 dark:text-gray-300">
              Для получения более общей информации о файлах cookie, пожалуйста, прочитайте <a href="https://www.termsfeed.com/blog/sample-cookies-policy-template/#What_Are_Cookies" className="text-blue-500">"Статья о файлах cookie с веб-сайта TerminalFeed</a>.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4 dark:text-gray-100">Поставщики услуг</h2>
            <p className="mb-4 dark:text-gray-300">
              Мы можем нанимать сторонние компании и частных лиц по следующим причинам:
            </p>
            <ul className="list-disc pl-8 mb-4 dark:text-gray-300">
              <li>Для облегчения нашего Сервиса;</li>
              <li>Для предоставления Сервиса от нашего имени;</li>
              <li>Для оказания услуг, связанных с Сервисом; или же</li>
              <li>Чтобы помочь нам проанализировать, как используется наш Сервис.</li>
            </ul>
            <p className="mb-4 dark:text-gray-300">
              Мы хотим сообщить пользователям нашего Сервиса, что эти третьи стороны имеют доступ к вашей Личной информации. Причина в том, чтобы выполнять возложенные на них задачи от нашего имени. Однако они обязаны не разглашать и не использовать информацию для каких-либо других целей.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4 dark:text-gray-100">Безопасность</h2>
            <p className="mb-4 dark:text-gray-300">
              Мы ценим ваше доверие в предоставлении нам вашей личной информации, поэтому мы стремимся использовать коммерчески приемлемые средства ее защиты. Но помните, что ни один метод передачи через Интернет или метод электронного хранения не является на 100% безопасным и надежным, и мы не можем гарантировать его абсолютную безопасность.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4 dark:text-gray-100">Ссылки на другие сайты</h2>
            <p className="mb-4 dark:text-gray-300">
              Наш Сервис может содержать ссылки на другие сайты. Если вы нажмете на стороннюю ссылку, вы будете перенаправлены на этот сайт. Обратите внимание, что эти внешние сайты не управляются нами. Поэтому мы настоятельно рекомендуем вам ознакомиться с Политикой конфиденциальности этих веб-сайтов. Мы не контролируем и не несем ответственности за содержание, политику конфиденциальности или действия любых сторонних сайтов или служб.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4 dark:text-gray-100">Конфиденциальность детей</h2>
            <p className="mb-4 dark:text-gray-300">
              Наши Услуги не предназначены для лиц моложе 13 лет. Мы не собираем преднамеренно личную информацию от детей младше 13 лет. В случае, если мы обнаружим, что ребенок младше 13 лет предоставил нам личную информацию, мы немедленно удалим ее с наших серверов. Если вы являетесь родителем или опекуном и знаете, что ваш ребенок предоставил нам личную информацию, свяжитесь с нами, чтобы мы могли предпринять необходимые действия.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4 dark:text-gray-100">Изменения в настоящей Политике конфиденциальности</h2>
            <p className="mb-4 dark:text-gray-300">
              Мы можем время от времени обновлять нашу Политику конфиденциальности. Поэтому мы советуем вам периодически просматривать эту страницу на предмет любых изменений. Мы сообщим вам о любых изменениях, опубликовав новую Политику конфиденциальности на этой странице. Эти изменения вступают в силу немедленно после публикации на этой странице.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4 dark:text-gray-100">Мобильное приложение Политике конфиденциальности</h2>
            <p className="mb-4 dark:text-gray-300">
              А так же можете прочитать политика конфиденциальности для приложения.
              <a href="https://pages.flycricket.io/meteo-live-0/privacy.html" className="text-blue-500">Ссылка политика</a>
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4 dark:text-gray-100">Связаться с нами</h2>
            <p className="mb-4 dark:text-gray-300">
              Если у вас есть какие-либо вопросы или предложения относительно нашей Политики конфиденциальности, не стесняйтесь обращаться ко мне по адресу <a href="mailto:polezhar9310@gmail.com" className="text-blue-500">polezhar9310@gmail.com</a>.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;