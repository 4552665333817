
// src/components/ScreenArea.js
// src/components/ScreenArea.js
import React, {useState, useEffect, useRef, useContext} from 'react';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import drag1 from '../assets/images/drag1.png';
import drag2 from '../assets/images/drag2.png';
import drag3 from '../assets/images/drag3.png';

import { ThemeContext } from '../ThemeContext';


import screen01Light from '../assets/images/screeen/screen01-light.png';
import screen01Dark from '../assets/images/screeen/screen01-dark.png';
import screen02Light from '../assets/images/screeen/screen02-light.png';
import screen02Dark from '../assets/images/screeen/screen02-dark.png';
import screen03Light from '../assets/images/screeen/screen03-light.png';
import screen03Dark from '../assets/images/screeen/screen03-dark.png';

const images = {
  screen01: {
    light: screen01Light,
    dark: screen01Dark,
  },
  screen02: {
    light: screen02Light,
    dark: screen02Dark,
  },
  screen03: {
    light: screen03Light,
    dark: screen03Dark,
  },
};

function ScreenArea() {
  const swiperElRef = useRef(null);

  useEffect(() => {
    const swiper = new Swiper(swiperElRef.current, {
      modules: [Navigation],
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
    });

    return () => {
      swiper.destroy();
    };
  }, []);
  
  
  const { theme } = useContext(ThemeContext);

const [isSystemDark, setIsSystemDark] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  // Слушатель изменений системной темы
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => setIsSystemDark(e.matches);

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  // Функция для выбора изображения в зависимости от темы
  const getImagePath = (baseName) => {
    if (theme === 'system') {
      return isSystemDark ? images[baseName].dark : images[baseName].light;
    }
    return theme === 'dark' ? images[baseName].dark : images[baseName].light;
  };

  return (
    <>
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="row">
            <div className="main_drag">
              <div className="col-md-12">
                {/* Swiper container с ref и явной высотой */}
                <div className="swiper-container  h-100" ref={swiperElRef}>
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <img src={drag1} alt="Slide 1" className="w-full h-full object-cover" />
                    </div>
                    <div className="swiper-slide">
                      <img src={drag2} alt="Slide 2" className="w-full h-full object-cover" />
                    </div>
                    <div className="swiper-slide">
                      <img src={drag3} alt="Slide 3" className="w-full h-full object-cover" />
                    </div>
                  </div>
                  <div className="swiper-button-prev text-gray-700 dark:text-gray-300"></div>
                  <div className="swiper-button-next text-gray-700 dark:text-gray-300"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="screen_area" className="screen_area py-20 bg-gray-100 dark:bg-gray-800">
        <div className="container mx-auto px-4">
          <div className="row">
            <div className="main_screen">
              <div className="col-md-8 mx-auto text-center">
                <div className="head_title mb-8">
                  <h2 className="text-3xl font-bold text-gray-800 dark:text-gray-100">Скриншоты</h2>
                  <h5 className="text-gray-600 dark:text-gray-300">Самые яркие образы</h5>
                </div>
              </div>

              {/* Скриншот 1 */}
              <div className="screen01">
                <div className="col-md-6">
                  <div className="screen01_img text-center">
                    <div className="attr_cloudy text-white bg-gray-800 dark:bg-gray-600 p-4 rounded-full">
                      Пасмурно
                    </div>
                    <div className="attr_deg2 text-white bg-gray-700 dark:bg-gray-500 p-4 rounded-full">
                      11&deg;
                    </div>
                    <img
                      src={getImagePath('screen01')}
                      alt="Screen 1"
                      className="w-full"
                    />
                    {/*eslint-disable-next-line jsx-a11y/heading-has-content*/}
                    <h1 className="cloudy"></h1>
                  </div>
                </div>
                <div className="col-md-6 mt-20 md:mt-0">
                  <div className="screen01_content">
                    <div className="screen01_content_img text-center">
                      <img
                        src={require('../assets/images/cloud1.png')}
                        alt="Cloud"
                        className="mx-auto"
                      />
                    </div>
                    <h2 className="text-2xl mt-10 text-gray-800 dark:text-gray-100">Когда облака</h2>
                    <p className="mt-5 text-gray-600 dark:text-gray-300">
                      Просматривайте прогнозы погоды.
                    </p>

                    <ul className="list-inline mt-10 flex justify-center space-x-5">
                      <li className="text-center text-gray-800 dark:text-gray-100 bg-gray-200 dark:bg-gray-700 p-2 rounded-lg">95%</li>
                      <li className="text-center text-gray-800 dark:text-gray-100 bg-gray-200 dark:bg-gray-700 p-2 rounded-lg">9&deg;</li>
                      <li className="text-center text-gray-800 dark:text-gray-100 bg-gray-200 dark:bg-gray-700 p-2 rounded-lg">Rainy</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Скриншот 2 */}
              <div className="screen02 mt-40">
                <div className="col-md-6 order-first md:order-none">
                  <div className="screen02_img text-center">
                    <div className="attr_sun2 text-white bg-yellow-500 dark:bg-yellow-600 p-4 rounded-full">
                      Карта
                    </div>
                    <div className="attr_deg3 text-white bg-orange-500 dark:bg-orange-600 p-4 rounded-full">
                      Меню
                    </div>
                    <img
                      src={getImagePath('screen02')}
                      alt="Screen 2"
                      className="w-full"
                    />
                                        {/*eslint-disable-next-line jsx-a11y/heading-has-content*/}
                    <h1 className="sunny"></h1>
                  </div>
                </div>
                <div className="col-md-6 mt-20 md:mt-0">
                  <div className="screen02_content">
                    <div className="screen02_content_img text-center">
                      <img
                        src={require('../assets/images/sun1.png')}
                        alt="Sun"
                        className="mx-auto"
                      />
                    </div>
                    <h2 className="text-2xl mt-10 text-gray-800 dark:text-gray-100">Карты</h2>
                    <p className="mt-5 text-gray-600 dark:text-gray-300">
                      Просматривайте карты, показывающие осадки, отметки молний и т.д.
                    </p>

                    <ul className="list-inline mt-10 flex justify-center space-x-5">
                      <li className="text-center text-gray-800 dark:text-gray-100 bg-gray-200 dark:bg-gray-700 p-2 rounded-lg">95%</li>
                      <li className="text-center text-gray-800 dark:text-gray-100 bg-gray-200 dark:bg-gray-700 p-2 rounded-lg">9&deg;</li>
                      <li className="text-center text-gray-800 dark:text-gray-100 bg-gray-200 dark:bg-gray-700 p-2 rounded-lg">Rainy</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Скриншот 3 */}
              <div className="screen03 mt-40">
                <div className="col-md-6">
                  <div className="screen03_img text-center">
                    <div className="attr_rainy2 text-white bg-blue-500 dark:bg-blue-600 p-4 rounded-full">
                      Меню
                    </div>
                    <div className="attr_deg4 text-white bg-blue-700 dark:bg-blue-800 p-4 rounded-full">
                      Красивый
                    </div>
                    <img
                      src={getImagePath('screen03')}
                      alt="Screen 3"
                      className="w-full"
                    />
                                        {/*eslint-disable-next-line jsx-a11y/heading-has-content*/}
                    <h1 className="rainy"></h1>
                  </div>
                </div>
                <div className="col-md-6 mt-20">
                  <div className="screen03_content">
                    <div className="screen03_content_img text-center">
                      <img
                        src={require('../assets/images/rainy1.png')}
                        alt="Rainy"
                        className="mx-auto"
                      />
                    </div>
                    <h2 className="text-2xl mt-10 text-gray-800 dark:text-gray-100">Меню</h2>
                    <p className="mt-5 text-gray-600 dark:text-gray-300">
                      Настройки (смена темы) и т.д.
                    </p>

                    <ul className="list-inline mt-10 flex justify-center space-x-5">
                      <li className="text-center text-gray-800 dark:text-gray-100 bg-gray-200 dark:bg-gray-700 p-2 rounded-lg">95%</li>
                      <li className="text-center text-gray-800 dark:text-gray-100 bg-gray-200 dark:bg-gray-700 p-2 rounded-lg">9&deg;</li>
                      <li className="text-center text-gray-800 dark:text-gray-100 bg-gray-200 dark:bg-gray-700 p-2 rounded-lg">Rainy</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ScreenArea;

