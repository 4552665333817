

// src/components/Footer.js
import React, { useState } from 'react';
import { FaVk, FaEnvelope, FaShieldAlt } from 'react-icons/fa';

import { Link } from 'react-router-dom';

function Footer() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);



  return (
    <footer className="dark:bg-gray-900 py-10">
      <div className="container mx-auto px-4">
        <div className="relative">
          {/* Основное содержимое футера */}
          <div className="flex items-center justify-between relative">
            {/* Левый раздел - Меню */}
            <div className="flex-1">
              {/* Кнопка мобильного меню */}
              <button
                type="button"
                className="dark:text-gray-300 hover:dark:hover:text-gray-400 focus:outline-none"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                aria-label="Toggle navigation"
              >
                <i className="fa fa-bars text-2xl"></i>
              </button>

              {/* Пункты меню */}
              <ul className={`absolute top-full left-0 w-64 bg-gray-900 dark:bg-gray-800 shadow-lg ${isMenuOpen ? 'block' : 'hidden'}`}>
                <li>
                  <a href="#home" className="block px-4 py-2 text-white dark:text-gray-300 hover:bg-gray-800 dark:hover:bg-gray-700">
                    Главная
                  </a>
                </li>
                <li>
                  <a href="#features" className="block px-4 py-2 text-white dark:text-gray-300 hover:bg-gray-800 dark:hover:bg-gray-700">
                    Функции
                  </a>
                </li>
                <li>
                  <a href="#reviews" className="block px-4 py-2 text-white dark:text-gray-300 hover:bg-gray-800 dark:hover:bg-gray-700">
                    Отзывы
                  </a>
                </li>
                <li>
                  <a href="#download" className="block px-4 py-2 text-white dark:text-gray-300 hover:bg-gray-800 dark:hover:bg-gray-700">
                    Скачать
                  </a>
                </li>
              </ul>
            </div>

            {/* Центр логотип */}
            <div className="flex-1 flex justify-center">
              <a href="#brand" className="block">
                <img
                  src="/assets/images/footer-logo.png"
                  width="50"
                  height="50"
                  className="logo"
                  alt="Footer Logo"
                />
              </a>
            </div>

            {/* Правый раздел - социальные иконки */}
            <div className="flex-1 flex justify-end space-x-4">
              <a href="https://vk.com/club94970269" className="dark:text-gray-300 hover:dark:hover:text-gray-400">
                <FaVk className="text-xl" />
              </a>
              <a href="mailto:polezhar9310@gmail.com" className="dark:text-gray-300 hover:dark:hover:text-gray-400">
                <FaEnvelope className="text-xl" />
              </a>
            <Link to="/privacy-policy" className="dark:text-gray-300 hover:dark:hover:text-gray-400">
           <FaShieldAlt className="text-xl" />
           </Link>
            </div>
          </div>
        </div>

        {/* Разделитель */}
        <div className="w-full h-px bg-gray-700 dark:bg-gray-600 my-8"></div>

        {/* Секция авторских прав */}
        <div className="text-center">
          <p className="dark:text-gray-500 mb-4">
            ©{' '}
            <a
              href="https://meteolive.ru"
              target="_blank"
              rel="noopener noreferrer"
              className="dark:text-gray-300 hover:dark:hover:text-gray-400"
            >
              WFL Live.
            </a>{' '}
            Все права защищены.
          </p>
          <a  href="https://freesoft.ru">freesoft.ru</a>
        </div>
      </div>

        <div>
        </div>
    </footer>
  );
}

export default Footer;

