import React from 'react';
import { Link } from 'react-router-dom'; // Импортируем Link для навигации

function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 transition-colors duration-300 p-4">
      <div className="text-center">
        {/* Заголовок 404 */}
        <h1 className="text-9xl font-bold text-indigo-600 dark:text-indigo-400">404</h1>
        {/* Сообщение об ошибке */}
        <p className="text-2xl font-semibold mt-4">Упс! Страница не найдена.</p>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          Кажется, мы не можем найти страницу, которую вы ищете.
        </p>
        {/* Кнопка для возврата на главную */}
        <Link
          to="/"
          className="mt-6 inline-block px-6 py-3 bg-indigo-600 text-white font-semibold rounded-lg hover:bg-indigo-700 transition-colors duration-300"
        >
          Вернуться на главную
        </Link>
      </div>
    </div>
  );
}

export default NotFound;