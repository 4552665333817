
// src/components/Home.js
import React from 'react';

function Home() {
  return (
    <section
      id="home"
      className="relative bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: "url('/assets/images/homebg.png')" }}
    >
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="w-full overflow-hidden pt-48 pb-56">
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2">
                <div className="home_text">
                  <h1 className="text-white dark:text-gray-100 font-ubuntu font-bold text-6xl leading-tight">
                    Meteo Live
                  </h1>
                </div>

                <div className="mt-10 ml-[-10px] flex space-x-2.5">
                  <a
                    href="#download"
                    className="inline-block bg-red-500 dark:bg-red-600 text-white dark:text-gray-100 font-semibold border border-red-500 dark:border-red-600 py-4 px-8 rounded transition-all duration-600 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-red-500 dark:hover:text-red-400 hover:border-gray-200 dark:hover:border-gray-700"
                  >
                    Скачать
                  </a>
                  <a
                    href="#features"
                    className="inline-block bg-blue-600 dark:bg-blue-700 text-white dark:text-gray-100 font-semibold border border-blue-600 dark:border-blue-700 py-4 px-8 rounded transition-all duration-600 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 hover:border-gray-200 dark:hover:border-gray-700"
                  >
                    Функции
                  </a>
                  <a
                    href="https://yoomoney.ru/to/410014823903442"
                    className="inline-block bg-violet-600 dark:bg-violet-600 text-white dark:text-gray-100 font-semibold border border-blue-600 dark:border-blue-700 py-4 px-8 rounded transition-all duration-600 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 hover:border-gray-200 dark:hover:border-gray-700"
                  >
                    Донат
                  </a>
                </div>
              </div>

              <div className="w-full md:w-1/2">
                <div className="relative text-center mt-12 md:mt-0">
                  <div className="absolute top-[10%] right-[6%] w-14 h-14 bg-white dark:bg-gray-700 rounded-full flex items-center justify-center">
                    9&deg;
                  </div>
                  <div className="absolute bottom-0 right-0 w-14 h-14 bg-yellow-200 dark:bg-yellow-300 rounded-full flex items-center justify-center">
                    13
                  </div>
                  <div className="absolute top-[40%] right-0 w-24 h-24 bg-blue-600 dark:bg-blue-700 rounded-full flex items-center justify-center text-white uppercase">
                    Москва
                  </div>
                  <div className="absolute top-[45%] left-0 w-24 h-24 bg-red-500 dark:bg-red-600 rounded-full flex items-center justify-center text-white uppercase">
                    Пасмурно
                  </div>
                  <img
                    src="assets/images/phone02.png"
                    alt="Phone"
                    className="max-w-full"
                  />
                </div>
              </div>
            </div>
            <div className="absolute left-1/2 bottom-[10%] transform -translate-x-1/2 w-10 h-10 bg-gray-100 dark:bg-gray-700 rounded-full flex items-center justify-center z-10">
              <a href="#features">
                <i className="fa fa-chevron-down text-red-600 dark:text-red-400 text-sm"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;

