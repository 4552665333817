


import React from 'react';

function Download() {
    return (
        <section 
            id="download" 
            className="download mt-24 relative overflow-hidden"
            aria-label="Скачать приложение"
        >
            <div className="absolute inset-0 bg-gradient-to-r from-[#0696f1] to-[#0477c5] opacity-95"></div>
            
            <div className="container mx-auto relative z-10 max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex flex-wrap items-center">
                    <div className="md:w-1/2 w-full">
                        <div className="py-8 md:py-16 px-4 md:px-8"> {/* Уменьшен padding по вертикали */}
                            <h2 className="text-white text-xl md:text-2xl lg:text-3xl font-bold tracking-tight">
                                Как скачать приложение?
                            </h2>
                            <h5 className="text-white/90 mt-4 leading-relaxed text-base md:text-lg font-medium">
                                Просто скачайте приложение из магазина.
                                Простое, красивое и удобное приложение погоды.
                                Только актуальная и полезная информация.
                            </h5>

                            <div className="mt-8 flex flex-col sm:flex-row gap-4 items-center"> {/* Уменьшен gap и margin-top */}
                                <a 
                                    href="https://play.google.com/store/apps/details?id=ru.wfllive.meteo" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="transform transition-all duration-300 hover:scale-105 hover:opacity-90"
                                >
                                    <img 
                                        className="w-[188px] h-[55px]"
                                        src="assets/imgstore/google-play-badge.png"
                                        alt="Доступно в Google Play"
                                        loading="lazy"
                                    />
                                </a>
                                <a 
                                    href="https://www.rustore.ru/catalog/app/ru.wfllive.meteo" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="transform transition-all duration-300 hover:scale-105 hover:opacity-90"
                                >
                                    <img 
                                        className="w-[188px] h-[55px]"
                                        src="assets/imgstore/rustore_app_logo.png"
                                        alt="Доступно в RuStore"
                                        loading="lazy"
                                    />
                                </a>
                                <a 
                                    href="https://appgallery.huawei.com/app/C107172511" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="transform transition-all duration-300 hover:scale-105 hover:opacity-90"
                                >
                                    <img 
                                        className="w-[200px] h-[75px]"
                                        src="assets/imgstore/galleryapps.png"
                                        alt="Доступно в Gallery Apps"
                                        loading="lazy"
                                    />
                                </a>
                                <a 
                                    href="https://vk.com/app51501768" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="transform transition-all duration-300 hover:scale-105 hover:opacity-90"
                                >
                                    <img 
                                        className="w-[200px] h-[75px]"
                                        src="assets/imgstore/miniapp.jpg"
                                        alt="Доступно в Mini Apps"
                                        loading="lazy"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="md:w-1/2 w-full flex items-center justify-center">
                        <div className="mt-4 md:mt-0 transform transition-all duration-500 hover:scale-105">
                            <img 
                                className="drop-shadow-2xl" 
                                /* Уменьшена амплитуда и период анимации */
                                src="assets/images/phone02l.png"
                                alt="Превью приложения"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none">
                <div className="absolute top-0 left-0 w-64 h-64 bg-white/5 rounded-full -translate-x-1/2 -translate-y-1/2"></div>
                <div className="absolute bottom-0 right-0 w-96 h-96 bg-white/5 rounded-full translate-x-1/2 translate-y-1/2"></div>
            </div>
        </section>
    );
}

export default Download;




