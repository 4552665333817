
import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Features from './components/Features';
import Reviews from './components/Reviews';
import Download from './components/Download';
import Footer from './components/Footer';
import ScreenArea from "./components/ScreenArea";
import Preloader from './components/Preloader';


function App() {
useEffect(() => {
    // Загрузка скрипта
    const script = document.createElement('script');
    script.src = 'https://ad.mail.ru/static/ads-async.js';
    script.async = true;
    document.body.appendChild(script);

    // Инициализация рекламного блока
    window.MRGtag = window.MRGtag || [];
    window.MRGtag.push({});

    // Очистка при размонтировании компонента
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
      <div className="flex flex-col min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 transition-colors duration-300">
      <Preloader />
        <Navbar />
        <Home />
              <ins
        className="mrg-tag"
        style={{ display: 'inline-block', width: '300px', height: '300px' }}
        data-ad-client="ad-1777095"
        data-ad-slot="1777095"
      ></ins>
        <Features />
        <ScreenArea />
        <Reviews />
        <Download />
        <Footer />
      </div>
  );
}

export default App;

