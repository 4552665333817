/*import React from 'react';
import './../assets/css/bootsnav.css'; // Import bootsnav CSS
import './../assets/css/font-awesome.min.css'; // Import font-awesome CSS
import './../assets/css/style.css'

function Navbar() {
    return (
        <nav className="navbar navbar-default bootsnav navbar-fixed white no-background">
            <div className="container">
                <div className="attr-nav">
                    <ul>
                        <li><a href="https://vk.com/club94970269"><i className="fa fa-vk"></i></a></li>
                        <li><a href="mailto:polezhar9310@gmail.com"><i className="fa fa-envelope"></i></a></li>
                        <li><a href="/policity.html"><i className="fa fa-shield"></i></a></li>
                    </ul>
                </div>
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                        <i className="fa fa-bars"></i>
                    </button>
                    <a className="navbar-brand" href="#brand">
                        <img src="../assets/images/logo.png" width="50" height="50" className="logo logo-display" alt="" />
                        <img src="assets/images/footer-logo.png" width="50" height="50" className="logo logo-scrolled" alt="" />
                    </a>
                </div>
                <div className="collapse navbar-collapse" id="navbar-menu">
                    <ul className="nav navbar-nav navbar-center">
                        <li><a href="#home">Главная</a></li>
                        <li><a href="#features">Функции</a></li>
                        <li><a href="#reviews">Отзывы</a></li>
                        <li><a href="#download">Скачать</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;*/ 

/*
import React, { useState } from 'react';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav
      className="fixed top-0 left-0 w-full z-50 shadow-md"
      style={{
        backgroundColor: 'rgba(219,219,219,0.8)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
      }}
    >
      <div className="container mx-auto px-4 py-6">
        <div className="flex items-center justify-between relative max-w-full">
          <div className="flex items-center">
            <button
              type="button"
              className="text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900"
              onClick={toggleMenu}
              aria-controls="navbar-menu"
              aria-expanded={isMenuOpen}
              aria-label="Toggle navigation"
            >
              <i className="fa fa-bars text-2xl"></i>
            </button>
          </div>

          <div className="flex justify-center items-center absolute left-1/2 transform -translate-x-1/2">
            <a className="navbar-brand" href="#brand">
              <img
                src="../assets/images/logo.png"
                width="50"
                height="50"
                className="logo logo-display"
                alt="Logo"
              />
              <img
                src="assets/images/footer-logo.png"
                width="50"
                height="50"
                className="logo logo-scrolled hidden"
                alt="Logo Scrolled"
              />
            </a>
          </div>

          <div className="flex items-center space-x-4">
            <a href="https://vk.com/club94970269" className="hover:text-gray-600">
              <i className="fa fa-vk text-xl"></i>
            </a>
            <a href="mailto:polezhar9310@gmail.com" className="hover:text-gray-600">
              <i className="fa fa-envelope text-xl"></i>
            </a>
            <a href="/policity.html" className="hover:text-gray-600">
              <i className="fa fa-shield text-xl"></i>
            </a>
          </div>
        </div>

        <div
          className={`
            absolute left-0 w-64 bg-white shadow-md rounded-md
            transition-all duration-300 ease-in-out
            ${isMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}
          `}
          style={{
            top: 'calc(100% + 1px)',
            maxHeight: 'calc(100vh - 100%)',
            overflowY: 'auto',
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            backdropFilter: 'blur(5px)',
            WebkitBackdropFilter: 'blur(5px)'
          }}
        >
          <ul className="py-2">
            <li>
              <a
                href="#home"
                className="block px-4 py-2 hover:bg-gray-100 hover:text-blue-500 transition-colors"
              >
                Главная
              </a>
            </li>
            <li>
              <a
                href="#features"
                className="block px-4 py-2 hover:bg-gray-100 hover:text-blue-500 transition-colors"
              >
                Функции
              </a>
            </li>
            <li>
              <a
                href="#reviews"
                className="block px-4 py-2 hover:bg-gray-100 hover:text-blue-500 transition-colors"
              >
                Отзывы
              </a>
            </li>
            <li>
              <a
                href="#download"
                className="block px-4 py-2 hover:bg-gray-100 hover:text-blue-500 transition-colors"
              >
                Скачать
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
*/

// src/components/Navbar.js
import React, { useState, useContext } from 'react';
import { ThemeContext } from '../ThemeContext';
import { FiSun, FiMoon, FiMonitor, FiMenu, FiX } from 'react-icons/fi';
import { FaVk, FaEnvelope, FaShieldAlt } from 'react-icons/fa';
import { IoMdArrowDropdown } from 'react-icons/io';

import { Link } from 'react-router-dom';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isThemeMenuOpen, setIsThemeMenuOpen] = useState(false);

  const { theme, setSpecificTheme } = useContext(ThemeContext); // Removed toggleTheme

const toggleNavMenu = () => {
  setIsMenuOpen(!isMenuOpen);
};

const toggleThemeMenu = () => {
  setIsThemeMenuOpen(!isThemeMenuOpen);
};

const handleThemeSelect = (selectedTheme) => {
  setSpecificTheme(selectedTheme);
  setIsThemeMenuOpen(false);
};

  const renderThemeIcon = () => {
    if (theme === 'dark') {
      return <FiMoon className="text-xl" />;
    } else if (theme === 'light') {
      return <FiSun className="text-xl" />;
    } else {
      return <FiMonitor className="text-xl" />;
    }
  };

  return (
    <nav
      className="fixed top-0 left-0 w-full z-50 shadow-md bg-white dark:bg-gray-800 bg-opacity-80 dark:bg-opacity-80 backdrop-filter backdrop-blur-lg transition-colors duration-300"
    >
      <div className="container mx-auto px-4 py-8">
        <div className="flex items-center justify-between relative max-w-full">
          {/* Левый раздел с кнопкой меню */}
          <div className="flex items-center">
            <button
              type="button"
              className="text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white focus:outline-none"
              onClick={toggleNavMenu}
              aria-controls="navbar-menu"
              aria-expanded={isMenuOpen}
              aria-label="Toggle navigation"
            >
              {isMenuOpen ? <FiX className="text-2xl" /> : <FiMenu className="text-2xl" />}
            </button>
          </div>

          {/* Центр логотип */}
          <div className="flex justify-center items-center absolute left-1/2 transform -translate-x-1/2">
            <a className="navbar-brand" href="#brand">
              <img
                src="/assets/images/logo.png"
                width="50"
                height="50"
                className="logo logo-display"
                alt="Logo"
              />
              <img
                src="/assets/images/footer-logo.png"
                width="50"
                height="50"
                className="logo logo-scrolled hidden"
                alt="Logo Scrolled"
              />
            </a>
          </div>

          {/* Правый раздел с социальными иконками и переключателем темы */}
          <div className="flex items-center space-x-4">
            <a href="https://vk.com/club94970269" className="hover:text-gray-600 dark:hover:text-gray-400">
              <FaVk className="text-xl" />
            </a>
            <a href="mailto:polezhar9310@gmail.com" className="hover:text-gray-600 dark:hover:text-gray-400">
              <FaEnvelope className="text-xl" />
            </a>
            <Link to="/privacy-policy" className="dark:text-gray-300 hover:dark:hover:text-gray-400">
           <FaShieldAlt className="text-xl" />
           </Link>
            {/* Меню переключения темы */}
            <div className="relative">
              <button
                onClick={toggleThemeMenu}
                className="flex items-center focus:outline-none text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                aria-label="Toggle Theme Menu"
              >
                {renderThemeIcon()}
                <IoMdArrowDropdown className="ml-1 text-lg" />
              </button>
              {isThemeMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-700 rounded-md shadow-lg z-20 transition transform ease-out duration-100 opacity-100 scale-100">
                  <ul className="py-1">
                    <li>
                      <button
                        onClick={() => handleThemeSelect('system')}
                        className="w-full text-left px-4 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 flex items-center"
                      >
                        <FiMonitor className="mr-2" />
                        Системная
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => handleThemeSelect('light')}
                        className="w-full text-left px-4 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 flex items-center"
                      >
                        <FiSun className="mr-2" />
                        Светлая
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => handleThemeSelect('dark')}
                        className="w-full text-left px-4 py-2 text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 flex items-center"
                      >
                        <FiMoon className="mr-2" />
                        Тёмная
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Dropdown Menu навигации */}
        <div
          className={`
            absolute left-0 w-full md:w-64 bg-white dark:bg-gray-800 shadow-md rounded-md
            transition-all duration-300 ease-in-out
            ${isMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}
          `}
          style={{
            top: 'calc(100% + 1px)',
            maxHeight: 'calc(100vh - 100%)',
            overflowY: 'auto',
            backdropFilter: 'blur(5px)',
            WebkitBackdropFilter: 'blur(5px)',
          }}
        >
          <ul className="py-2">
            <li>
              <a
                href="#home"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-blue-400 transition-colors"
              >
                Главная
              </a>
            </li>
            <li>
              <a
                href="#features"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-blue-400 transition-colors"
              >
                Функции
              </a>
            </li>
            <li>
              <a
                href="#reviews"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-blue-400 transition-colors"
              >
                Отзывы
              </a>
            </li>
            <li>
              <a
                href="#download"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-blue-400 transition-colors"
              >
                Скачать
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

