import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Preloader = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    setIsVisible(true);
    
    const timer = setTimeout(() => {
      setIsVisible(false);
      
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }, 1000);

    return () => clearTimeout(timer);
  }, [location]);

  if (!isLoading) return null;

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center bg-white dark:bg-gray-900
      transition-all duration-500 ease-in-out
      ${!isVisible ? 'opacity-0 scale-150' : 'opacity-100 scale-100'}`}>
      <div className="flex space-x-4">
        <div className="animate-bounce delay-100">
          <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
        </div>
        <div className="animate-bounce delay-200">
          <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
        </div>
        <div className="animate-bounce delay-300">
          <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
        </div>
        <div className="animate-bounce delay-400">
          <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;