
// src/components/Reviews.js
import React from 'react';
import { FaQuoteLeft, FaQuoteRight, FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import { motion } from 'framer-motion';

function Reviews() {
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= Math.floor(rating)) {
        stars.push(<FaStar key={i} className="text-yellow-400 inline" />);
      } else if (i - rating < 1) {
        stars.push(<FaStarHalfAlt key={i} className="text-yellow-400 inline" />);
      } else {
        stars.push(<FaRegStar key={i} className="text-yellow-400 inline" />);
      }
    }
    return stars;
  };

  const reviews = [
    {
      text: "Разработчик молодец, очень хорошо постарался. Приложение отлично показывает погоду по моему местоположению. Очень удобно и практично, мне все нравится). Но я бы советовал разработчику поработать с текстом в приложении, а то не совсем понятно что и где написано.",
      rating: 5,
      author: "E-Crew Веб-студия",
    },
    {
      text: "Пришёл из Telegram канала. Ставлю 5 звёзд. Всё сделано красиво, понятно, а самое главное - правильно погоду показывает. Респект!👍🏼",
      rating: 5,
      author: "САЛАВАТ СИБГАТОВ",
    },
    {
      text: "Крутое приложение, рекомендую.",
      rating: 4.5,
      author: "Пользователь Google",
    },
    {
      text: "Все супер :) Ставлю 5 авансом;)",
      rating: 5,
      author: "Пользователь Google",
    },
  ];

  // Анимационные настройки
  const animation = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <section id="reviews" className="reviews mt-24 px-4">
      <div className="container mx-auto">
        <div className="flex flex-col items-center">
          <div className="w-full md:w-8/12 text-center">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-800 dark:text-gray-100 mb-2">
              Что говорят отзывы
            </h2>
            <h5 className="text-lg md:text-xl text-gray-500 dark:text-gray-400">
              Отзывы из Google Play
            </h5>
          </div>

          <div className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-8 w-full">
            {reviews.map((review, index) => (
              <motion.div
                key={index}
                className="bg-white dark:bg-gray-700 shadow-lg dark:shadow-gray-800 rounded-lg p-6 relative hover:shadow-2xl transition-shadow duration-300"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={animation}
                transition={{ delay: index * 0.2, duration: 0.5 }}
              >
                <FaQuoteLeft className="text-blue-500 dark:text-blue-400 text-3xl absolute top-4 left-4" />
                <p className="text-gray-700 dark:text-gray-300 mt-4 mb-6">{review.text}</p>
                <div className="mb-4">
                  {renderStars(review.rating)}
                </div>
                <h5 className="text-gray-600 dark:text-gray-200 font-semibold">{review.author}</h5>
                <FaQuoteRight className="text-blue-500 dark:text-blue-400 text-3xl absolute bottom-4 right-4" />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Reviews;
